import { useAuth } from '../../../contexts/AuthContext';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';
import { Feature } from '../../../generated/graphql';
import CalendlyLogo from '../../svgs/CalendlyLogo';
import GoogleCalLogo from '../../svgs/GoogleCalLogo';
import IllustrationCalendar from '../../svgs/IllustrationCalendar';
import StripeLogo from '../../svgs/StripeLogo';
import IntegrationCard from './IntegrationCard';

export default function IntegrationsSection() {
  const { authedProviderUser } = useAuth();

  const { isFeatureEnabled } = useFeatureFlags();

  const isStripeIntegrationFeatureEnabled = isFeatureEnabled(
    Feature.StripeIntegration,
  );

  return (
    <div className="grid max-w-[1080px] grid-cols-3 gap-4 py-5">
      <IntegrationCard
        logo={
          <div className="flex items-center space-x-2.5">
            <IllustrationCalendar className="h-8 w-8 flex-shrink-0 text-blue-100" />
            <span className="text-subtitle-small font-light text-green-150 line-clamp-2">
              Calendar sync
            </span>
          </div>
        }
        name="Calendar sync"
        description={
          <div className="flex flex-col items-start gap-y-4">
            <div>
              Sync your external calendar with Homecoming to view all your
              events in one place.
            </div>
            <GoogleCalLogo className="h-6 w-6 flex-shrink-0" />
          </div>
        }
        isConnected={Boolean(authedProviderUser?.hasNylasIntegration)}
        integrationPath="/integrations/calendar"
      />
      {isStripeIntegrationFeatureEnabled && (
        <IntegrationCard
          logo={<StripeLogo className="h-8" />}
          name="Stripe"
          description="Send and track client invoices, and notify clients of past due invoices."
          isConnected={Boolean(authedProviderUser?.provider.stripeAccountId)}
          integrationPath="/integrations/stripe"
        />
      )}
      <IntegrationCard
        logo={<CalendlyLogo className="h-8" />}
        name="Calendly"
        description="Sync your calendar with Homecoming and allow clients to schedule sessions."
        isConnected={Boolean(authedProviderUser?.provider.calendlyIntegration)}
        integrationPath="/integrations/calendly"
      />
    </div>
  );
}
