import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ChatIcon,
  InboxIcon,
  MailIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';

import {
  ActivityType,
  PatientDataPermission,
  useUninvitedEventInviteesQuery,
  useMeProviderProgramTagsWithCountQuery,
  useProviderUserSignableFormsCountQuery,
  useProviderUserSignableFormsLazyQuery,
} from '../../../generated/graphql';

import { useAuth } from '../../../contexts/AuthContext';
import { useGlobalModalsContext } from '../../../contexts/GlobalModalsContext';

import {
  HAS_DISMISSED_BOOK_A_CALL,
  HAS_DISMISSED_PAYMENTS_SETUP,
  HAS_DISMISSED_SCHEDULE_SETUP,
  HAS_DISMISSED_STOREFRONT_SETUP,
  HAS_DISMISSED_ADD_CALENDLY_CLIENTS_SETUP,
  HAS_DISMISSED_CALENDAR_SETUP,
} from '../../lib/storage';

import { openBookACallLink } from '../../lib/url';
import { AnalyticsPage } from '../../../lib/analytics';
import { defaultTransitionProps } from '../../lib/animation';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';

import BanknotesIcon from '../../svgs/BanknotesIcon';
import DocumentPlusIcon from '../../svgs/DocumentPlusIcon';
import IllustrationFaces from '../../svgs/IllustrationFaces';
import IllustrationCalendar from '../../svgs/IllustrationCalendar';

import { PatientRow } from '../../types/PatientManagement';

import useLocalStorageValue from '../../hooks/useLocalStorageValue';

import Button from '../../components/Button';
import AddPDFModal from '../../components/Modals/AddPDFModal';
import AddLinkModal from '../../components/Modals/AddLinkModal';
import AddToLibraryMenu from '../../components/AddToLibraryMenu';
import AddAudioModal from '../../components/Modals/AddAudioModal';
import AddImageModal from '../../components/Modals/AddImageModal';
import PageContainer from '../../components/Containers/PageContainer';
import AddPatientsModal from '../../components/Modals/AddPatientsModal';
import AddOrEditTextModal from '../../components/Modals/AddOrEditTextModal';

import { isNotification } from './helpers';
import { QuickActionProps } from './QuickAction';
import NotificationItem from './NotificationItem';
import SelectPatientModal from './SelectPatientModal';
import OnboardingNotification from './OnboardingNotification';
import UnsignedFormItem from './UnsignedFormItem';
import Spinner from '../../svgs/Spinner';
import FormBellBadge from '../../svgs/FormBellBadge';

const Home = () => {
  const navigate = useNavigate();

  const {
    authedProviderUser,
    refreshAuthedProviderUserNotifications,
    willHitClientLimit,
  } = useAuth();
  const { showUpgradeModal } = useGlobalModalsContext();

  const isOwnerOrAdmin =
    isProviderUserOwnerOrAdminAuthorized(authedProviderUser);

  const hasNylasIntegration = Boolean(authedProviderUser?.hasNylasIntegration);

  const hasCalendlyIntegration = Boolean(
    authedProviderUser?.provider.calendlyIntegration,
  );

  const hasStripeIntegration = Boolean(
    authedProviderUser?.provider.stripeAccountId,
  );

  const { data: uninvitedEventInviteesData } = useUninvitedEventInviteesQuery();

  const { data: signableFormsCountData } =
    useProviderUserSignableFormsCountQuery();

  const unsignedFormsCount =
    signableFormsCountData?.providerUserSignableFormsCount;

  const [
    getSignableForms,
    { data: signableFormsData, loading: isLoadingSignableForms },
  ] = useProviderUserSignableFormsLazyQuery();

  const unsignedForms = signableFormsData?.providerUserSignableForms;

  const uniqueInvitees: PatientRow[] | undefined =
    uninvitedEventInviteesData?.uninvitedEventInvitees.map((invitee) => ({
      name: `${invitee.firstName} ${invitee.lastName}`,
      email: invitee.email,
    }));

  const [showPrefilledPatientsList, setShowPrefilledPatientsList] =
    useState(false);

  const [hasDismissedBookACallStep, setHasDismissedBookACallStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_BOOK_A_CALL,
      authedProviderUser.email,
    );

  const [hasDismissedCalendarStep, setHasDismissedCalendarStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_CALENDAR_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedScheduleStep, setHasDismissedScheduleStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_SCHEDULE_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedPaymentsStep, setHasDismissedPaymentsStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_PAYMENTS_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedCalendlyClientsStep, setHasDismissedCalendlyClientsStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_ADD_CALENDLY_CLIENTS_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedStorefrontStep, setHasDismissedStorefrontStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_STOREFRONT_SETUP,
      authedProviderUser.email,
    );

  const showBookACall = Boolean(
    authedProviderUser && !hasDismissedBookACallStep,
  );

  const showCalendarSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasNylasIntegration &&
    !hasDismissedCalendarStep;

  const showSchedulingSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasCalendlyIntegration &&
    !hasDismissedScheduleStep;

  const showPaymentsSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasStripeIntegration &&
    !hasDismissedPaymentsStep;

  const showCalendlyClientsSetup = Boolean(
    authedProviderUser &&
      hasCalendlyIntegration &&
      !hasDismissedCalendlyClientsStep,
  );

  const showSetupStorefront = Boolean(
    authedProviderUser &&
      isOwnerOrAdmin &&
      !authedProviderUser.storefrontSlug &&
      !hasDismissedStorefrontStep,
  );

  const onboardingSteps: QuickActionProps[] = useMemo(
    () => [
      ...(showBookACall
        ? [
            {
              title: 'Book a call with us',
              description: 'Get personal guidance from the Homecoming team',
              IconComponent: IllustrationFaces,
              iconClassName: 'text-purple-125',
              buttonTitle: 'Book a Call',
              onClick: openBookACallLink,
              onDismiss: () => setHasDismissedBookACallStep(true),
            },
          ]
        : []),
      ...(showCalendarSetup
        ? [
            {
              title: 'Set up your calendar',
              description: 'Sync your external calendar with Homecoming',
              IconComponent: IllustrationCalendar,
              iconClassName: 'text-green-125',
              buttonTitle: 'Connect Calendar',
              onClick: () => {
                navigate('/integrations/calendar');
              },
              onDismiss: () => setHasDismissedCalendarStep(true),
            },
          ]
        : []),
      ...(showPaymentsSetup
        ? [
            {
              title: 'Set up payments',
              description: 'Connect your Stripe account with Homecoming',
              IconComponent: IllustrationCalendar,
              iconClassName: 'text-green-125',
              buttonTitle: 'Connect Stripe',
              onClick: () => {
                navigate('/integrations/stripe');
              },
              onDismiss: () => setHasDismissedPaymentsStep(true),
            },
          ]
        : []),
      ...(showSchedulingSetup
        ? [
            {
              title: 'Set up client bookings',
              description:
                'Connect your Calendly account to allow clients to book appointments',
              IconComponent: IllustrationCalendar,
              iconClassName: 'text-green-125',
              buttonTitle: 'Connect Calendly',
              onClick: () => {
                navigate('/integrations/calendly');
              },
              onDismiss: () => setHasDismissedScheduleStep(true),
            },
          ]
        : []),
      ...(showCalendlyClientsSetup && uniqueInvitees?.length
        ? [
            {
              title: 'Import clients',
              description:
                'Some clients from Calendly are not on Homecoming, would you like to add them?',
              IconComponent: IllustrationFaces,
              iconClassName: 'text-green-125 h-32 w-32',
              onClick: () => {
                setShowPrefilledPatientsList(true);
                addPatients();
              },
              onDismiss: () => setHasDismissedCalendlyClientsStep(true),
            },
          ]
        : []),
      ...(showSetupStorefront
        ? [
            {
              title: 'Set up your webpage',
              description:
                'Customize your webpage and start accepting new clients',
              buttonTitle: 'Set up webpage',
              onClick: () => {
                navigate('/my-webpage');
              },
              onDismiss: () => setHasDismissedStorefrontStep(true),
            },
          ]
        : []),
    ],
    [
      authedProviderUser,
      uniqueInvitees,
      showBookACall,
      showCalendarSetup,
      showSchedulingSetup,
      showPaymentsSetup,
      showCalendlyClientsSetup,
      showSetupStorefront,
    ],
  );

  const addPatients = () => {
    if (willHitClientLimit()) {
      showUpgradeModal(AnalyticsPage.Home);
      return;
    }
    setIsAddPatientsModalOpen(true);
  };

  const notifications =
    authedProviderUser?.notifications?.filter(
      (notification) => !Boolean(notification.readAt),
    ) ?? [];

  // Refresh notifications when the page is loaded
  useEffect(() => {
    refreshAuthedProviderUserNotifications();
  }, []);

  const [showUnsignedForms, setShowUnsignedForms] = useState(false);

  const [isSelectClientModalOpen, setSelectClientModalOpen] = useState(false);
  const [isAddPatientsModalOpen, setIsAddPatientsModalOpen] = useState(false);

  const [isAddPDFModalOpen, setIsAddPDFModalOpen] = useState(false);
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);
  const [isAddAudioModalOpen, setIsAddAudioModalOpen] = useState(false);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
    useState(false);

  const openAddOrEditTextModal = () => setIsAddOrEditTextModalOpen(true);

  const onActivityAdded = (activityType: ActivityType) => {
    return async (activityId: string) => {
      navigate('/library');
    };
  };

  const {
    data: meProviderProgramTagsData,
    refetch: refetchMeProviderProgramTags,
  } = useMeProviderProgramTagsWithCountQuery();

  const providerProgramTags =
    meProviderProgramTagsData?.meProvider?.provider?.programTags ?? [];

  const allNotifications = [...onboardingSteps, ...notifications];

  return (
    <PageContainer>
      <Transition
        show={Boolean(authedProviderUser)}
        {...defaultTransitionProps}
      >
        <div className="mb-10 flex flex-row gap-x-2">
          <div className="mr-6 flex flex-col justify-center text-caption">
            Quick Actions
          </div>
          <Button
            title="Add Clients"
            IconComponent={UserIcon}
            theme="secondary-white"
            iconPosition="left"
            className="shadow-100"
            onClick={addPatients}
          />
          {authedProviderUser?.patientDataPermission !==
            PatientDataPermission.None && (
            <>
              <Button
                title="Send Mail"
                IconComponent={MailIcon}
                theme="secondary-white"
                iconPosition="left"
                className="!text-purple-100 shadow-100"
                onClick={() => navigate('/follow-ups/new')}
              />
              <Button
                title="Start Chat"
                IconComponent={ChatIcon}
                theme="secondary-white"
                iconPosition="left"
                className="!text-blue-100 shadow-100"
                onClick={() => setSelectClientModalOpen(true)}
              />
            </>
          )}
          <AddToLibraryMenu
            buttonClassName="!text-orange-100 shadow-100"
            buttonTheme="secondary-white"
            buttonSize="medium"
            onClickAddLink={() => setIsAddLinkModalOpen(true)}
            onClickAddImage={() => setIsAddImageModalOpen(true)}
            onClickAddPDF={() => setIsAddPDFModalOpen(true)}
            onClickAddAudio={() => setIsAddAudioModalOpen(true)}
            onClickAddForm={() =>
              navigate('/library/forms/new', {
                state: {
                  fromPage: 'home',
                },
              })
            }
            onClickAddText={openAddOrEditTextModal}
            IconComponent={DocumentPlusIcon}
          />
          <Button
            title="Invoice"
            IconComponent={BanknotesIcon}
            theme="secondary-white"
            iconPosition="left"
            className="!text-green-100 shadow-100"
            onClick={() => {
              if (hasStripeIntegration) {
                navigate('/payments/new');
              } else if (isOwnerOrAdmin) {
                navigate('/integrations/stripe');
              } else {
                navigate('/payments');
              }
            }}
          />
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="flex w-full flex-col">
            <div className="mb-6 flex h-9 w-full flex-row items-center justify-between">
              <div className="font-sans text-subtitle-small">
                {!showUnsignedForms ? 'Inbox' : 'Outstanding forms'}
              </div>
              <Transition
                show={unsignedFormsCount > 0}
                {...defaultTransitionProps}
              >
                <Button
                  title={
                    showUnsignedForms
                      ? `View inbox`
                      : `Complete ${unsignedFormsCount} outstanding ${
                          unsignedFormsCount === 1 ? 'form' : 'forms'
                        }`
                  }
                  IconComponent={
                    !showUnsignedForms
                      ? !isLoadingSignableForms
                        ? FormBellBadge
                        : Spinner
                      : InboxIcon
                  }
                  theme="secondary-white"
                  iconPosition="left"
                  className="!text-neutral-125 shadow-100"
                  onClick={async () => {
                    if (!showUnsignedForms) {
                      await getSignableForms();
                    }
                    setShowUnsignedForms(!showUnsignedForms);
                  }}
                />
              </Transition>
            </div>
            <div className="divide-y divide-neutral-50 rounded-lg border border-neutral-50 p-7">
              {showUnsignedForms ? (
                <>
                  {unsignedForms?.map((unsignedForm) => (
                    <UnsignedFormItem
                      key={`unsignedForm-${unsignedForm.id}`}
                      unsignedForm={unsignedForm}
                      unsignedForms={unsignedForms}
                    />
                  ))}
                </>
              ) : (
                <>
                  {allNotifications.map((elem, i) => {
                    if (isNotification(elem)) {
                      return (
                        <NotificationItem
                          key={`notification-${elem.id}`}
                          notification={elem}
                        />
                      );
                    } else {
                      return (
                        <OnboardingNotification
                          key={`onboardingNotification-${i}`}
                          step={elem}
                        />
                      );
                    }
                  })}
                </>
              )}
              {!Boolean(allNotifications.length) && (
                <div className="text-center text-body text-neutral-110">
                  Events related to your practice and your clients will appear
                  here.
                </div>
              )}
            </div>
          </div>
        </div>
        <SelectPatientModal
          isOpen={isSelectClientModalOpen}
          setClosed={() => setSelectClientModalOpen(false)}
        />
        <AddLinkModal
          isModalOpen={isAddLinkModalOpen}
          setClosed={() => setIsAddLinkModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Link)}
        />
        <AddPDFModal
          isModalOpen={isAddPDFModalOpen}
          setClosed={() => setIsAddPDFModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Pdf)}
        />
        <AddImageModal
          isModalOpen={isAddImageModalOpen}
          setClosed={() => setIsAddImageModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Image)}
        />
        <AddAudioModal
          isModalOpen={isAddAudioModalOpen}
          setClosed={() => setIsAddAudioModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Audio)}
        />
        <AddOrEditTextModal
          isModalOpen={isAddOrEditTextModalOpen}
          setClosed={() => setIsAddOrEditTextModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Text)}
        />
        <AddPatientsModal
          isClientsPage={false}
          isModalOpen={isAddPatientsModalOpen}
          setClosed={() => setIsAddPatientsModalOpen(false)}
          providerProgramTags={providerProgramTags}
          refetchMeProviderProgramTags={refetchMeProviderProgramTags}
          {...(showPrefilledPatientsList && {
            prefilledPatientsList: uniqueInvitees,
            isFromCalendly: true,
          })}
        />
      </Transition>
    </PageContainer>
  );
};

export default Home;
